import type React from 'react';
import classNames from 'classnames';
import styles from './BadgeGroup.module.scss';

type BadgeGroupProps = {
    children: React.ReactNode;
    offset?: 'sm' | 'md' | 'lg';
    posAbsolute?: 'top' | 'bottom';
};

const BadgeGroup: React.FC<BadgeGroupProps> = ({ children, offset, posAbsolute }) => (
    <div className={classNames(
        styles.BadgeGroup,
        { [styles.BadgeGroup_absolute_top as string]: posAbsolute === 'top' },
        { [styles.BadgeGroup_absolute_bottom as string]: posAbsolute === 'bottom' },
        { [styles.BadgeGroup_offset_sm as string]: offset === 'sm' },
        { [styles.BadgeGroup_offset_md as string]: offset === 'md' },
        { [styles.BadgeGroup_offset_lg as string]: offset === 'lg' },
    )} >{children}</div>
);

export default BadgeGroup;
