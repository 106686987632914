import type React from 'react';
import Translation from '../Translation/Translation';
import Badge from '../Badge/Badge';

type CoolerBadgeProps = {
    hideDesktop?: boolean;
    hideMobile?: boolean;
    isFree?: boolean;
};

const CoolerBadge: React.FC<CoolerBadgeProps> = ({
    hideDesktop = false,
    hideMobile = false,
    isFree = false,
}) => (
    <Badge colorScheme="blue" hideDesktop={hideDesktop} hideMobile={hideMobile}>
        {isFree && <Translation pageString='productinformatie' stringId='temperature_notification_cooler_free_badge' />}
        {!isFree && <Translation pageString='productinformatie' stringId='temperature_notification_cooler_paid_badge' />}
    </Badge>
);

export default CoolerBadge;
