import type React from 'react';
import ErrorLogger from '../../../js/error-logger/ErrorLogger';

const getUrl = (url: string) => {
    try {
        return new URL(url);
    } catch (error) {
        ErrorLogger.createFromGlobals()?.log(`Failed to construct URL in source component. Url: ${url}`);

        return null;
    }
};

// See documentation https://developer.fastly.com/reference/io/
export type ImageFormat =
    | 'avif'
    | 'bjpg'
    | 'gif'
    | 'jpg'
    | 'mp4'
    | 'pjpg'
    | 'png'
    | 'png8'
    | 'webp'
    | 'webpll'
    | 'webply';

export type SourceProps = {
    background?: string | false;
    fit?: string;
    forceHeight?: boolean;
    framing?: 'canvas' | 'crop' | false;
    imageFormat?: ImageFormat | false;
    imageHeight?: number;
    imageUrl?: string;
    imageWidth: number;
    minScreenWidth?: number;
    ratio?: string | false;
    smartImage?: boolean;
    trim?: string | false;
};

const Source: React.FC<SourceProps> = ({
    background = 'ffffff',
    fit,
    forceHeight = false,
    framing = 'canvas',
    imageFormat = 'jpg',
    imageHeight,
    imageUrl = '',
    imageWidth,
    minScreenWidth,
    ratio = '1:1',
    smartImage,
    trim,
}) => {
    const url = getUrl(imageUrl);

    if (!url) {
        return null;
    }

    if (framing && ratio) {
        url.searchParams.set(framing, ratio);

        if (smartImage && framing === 'crop') {
            url.searchParams.set(framing, `${ratio},smart`);
        }
    }

    Object.entries({
        'bg-color': background,
        fit,
        format: imageFormat,
        trim,
        width: imageWidth,
    }).forEach(([key, value]) => {
        if (value) {
            url.searchParams.set(key, String(value));
        }
    });

    if (forceHeight) {
        url.searchParams.set('height', String(imageHeight));
    }

    const decodedUri = decodeURIComponent(url.toString());
    const srcSet = `${decodedUri}&dpr=1.5 1.5x,`
        + `${decodedUri}&dpr=2 2x,`
        + `${decodedUri}`;

    return <source width={imageWidth} height={imageHeight} srcSet={srcSet} media={minScreenWidth ? `(min-width:${minScreenWidth}px)` : undefined} />;
};

export default Source;
