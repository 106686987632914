import type { PropsWithChildren } from 'react';
import React, { useState } from 'react';
import classNames from 'classnames';
import type { SourceProps } from './Source';
import Source from './Source';

interface PictureProps {
    alt: string;
    draggable?: boolean;
    fallbackUrl?: string;
    imageClass?: string;
    imageUrl: string;
    lazyLoad?: boolean;
}

const PictureContent: React.FC<PropsWithChildren<PictureProps>> = ({
    alt,
    children,
    draggable = undefined,
    fallbackUrl,
    imageClass,
    imageUrl,
    lazyLoad = true,
}) => {
    const [erroneous, setErroneous] = useState(false);

    const shouldLoadFallbackUrl = erroneous && fallbackUrl;
    const hasNoImageUrl = !imageUrl && fallbackUrl;
    const src = shouldLoadFallbackUrl || hasNoImageUrl ? fallbackUrl : imageUrl;

    let maxValueOfWidth = 0;
    let maxValueOfHeight = 0;

    const sourcesComponent = React.Children.toArray(children).map((child) => {
        if (!React.isValidElement<SourceProps>(child) || child.type !== Source) {
            return null;
        }

        maxValueOfWidth = child.props.imageWidth > maxValueOfWidth ? child.props.imageWidth : maxValueOfWidth;
        maxValueOfHeight = (child.props.imageHeight && child.props.imageHeight > maxValueOfHeight) ? child.props.imageHeight : maxValueOfHeight;

        return React.cloneElement(child, {
            ...child.props,
            imageUrl: src,
            key: `${child.props.imageWidth}${child.props.minScreenWidth ?? 0}`,
        });
    });

    return (
        <picture>
            {sourcesComponent}
            <img
                className={classNames(imageClass, { 'image-aspect-equal': maxValueOfWidth === maxValueOfHeight || !maxValueOfHeight }) || undefined}
                width={maxValueOfWidth}
                height={maxValueOfHeight || maxValueOfWidth}
                loading={lazyLoad ? 'lazy' : 'eager'}
                src={src}
                alt={alt}
                fetchpriority={lazyLoad ? 'low' : 'high'}
                draggable={draggable}
                onError={() => setErroneous(true)}
            />
        </picture>
    );
};

/**
 * We use an inner component so that we can key the state with the image URL
 * so that when the imageUrl changes, the entire component is remounted and its (possibly erroneous) state is reset
 */
const Picture: React.FC<PropsWithChildren<PictureProps>> = (props) => <PictureContent key={props.imageUrl} {...props}/>;

export default Picture;
