import type React from 'react';
import classNames from 'classnames';
import styles from './Badge.module.scss';

type BadgeProps = {
    children: React.ReactNode;
    colorScheme?: 'transparent' | 'red' | 'pink-lighter' | 'honey' | 'blue';
    customClassNames?: string;
    hideDesktop?: boolean;
    hideMobile?: boolean;
    lowerCase?: boolean;
    size?: 'sm' | 'md';
};

const Badge: React.FC<BadgeProps> = ({
    children,
    colorScheme = 'red',
    customClassNames,
    hideDesktop = false,
    hideMobile = false,
    lowerCase,
    size,
}) => (
    <span className={classNames(
        styles.Badge,
        customClassNames,
        { [styles.Badge_lowercase as string]: lowerCase },
        { [styles.Badge_small as string]: size === 'sm' },
        { [styles.Badge_color_red as string]: colorScheme === 'red' },
        { [styles.Badge_color_transparent as string]: colorScheme === 'transparent' },
        { [styles.Badge_color_pink_lighter as string]: colorScheme === 'pink-lighter' },
        { [styles.Badge_color_honey as string]: colorScheme === 'honey' },
        { [styles.Badge_color_blue as string]: colorScheme === 'blue' },
        { 'show-lg': hideMobile },
        { 'hide-lg': hideDesktop },
    )} >
        {children}
    </span>
);

export default Badge;
