import type React from 'react';
import useTranslate from './hooks/UseTranslate';

interface Props {
    pageString: string;
    placeholders?: Record<string, string>;
    strict?: boolean;
    stringId: string;
}

const Translation: React.FC<Props> = (
    { pageString, stringId, ...options },
) => useTranslate()(pageString, stringId, options);

export default Translation;
