import type React from 'react';
import { FormattedNumber } from 'react-intl';

export interface Price {
    currency: string;
    value: number;
}

interface FormatPriceProps {
    price: Price;
}

const FormatPrice: React.FC<FormatPriceProps> = ({ price: { currency, value } }) => (
    <FormattedNumber
        value={value}
        style='currency'
        currency={currency}
    />
);

export default FormatPrice;
